<!--
 * @Description: 创建报停服务单
 * @Author: ChenXueLin
 * @Date: 2021-10-12 13:39:18
 * @LastEditTime: 2022-06-22 10:31:46
 * @LastEditors: ChenXueLin
-->
<template>
  <div
    class="content-view-wrapper pd-10"
    v-loading="loading"
    :element-loading-background="loadingBackground"
  >
    <div class="content-view ">
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 基本信息 start -->
          <template>
            <div class="primaryTitle">报停服务申请</div>
            <div class="baseInfo-box">
              <div class="edit-title">基本信息</div>
              <el-form
                ref="baseInfoForm"
                class="editForm"
                label-position="right"
                :inline="true"
                label-width="120px"
                :rules="rules"
                :model="baseInfoForm"
              >
                <el-form-item label="客户名称" prop="corpId">
                  <e6-vr-select
                    v-model="baseInfoForm.corpId"
                    :data="corpList"
                    placeholder="客户名称"
                    title="客户名称"
                    clearable
                    virtual
                    remote
                    :is-title="true"
                    @filterChange="handleLoadCorpFilter"
                    @change="handleChange"
                    :props="{
                      id: 'corpId',
                      label: 'corpName'
                    }"
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="指派类型" prop="solueIsDept">
                  <el-radio-group v-model="baseInfoForm.solueIsDept">
                    <el-radio :label="1">部门</el-radio>
                    <el-radio :label="0">员工</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="指派部门"
                  prop="departmentId"
                  v-if="baseInfoForm.solueIsDept == 1"
                >
                  <!-- 部门下拉框 -->
                  <e6-select
                    v-model="baseInfoForm.departmentId"
                    :dataList="treeData"
                    :filterable="true"
                    :slotTemplate="'tree'"
                    :multiple="false"
                    :issingleleaf="false"
                    ref="t_select"
                  ></e6-select>
                </el-form-item>
                <el-form-item label="指派处理人" prop="solveRpid" v-else>
                  <all-user-search
                    v-model="baseInfoForm.solveRpid"
                    clear
                    placeholder="指派处理人"
                    title="指派处理人"
                    :propsConfig="{
                      id: 'employeeId',
                      label: 'userName'
                    }"
                  >
                  </all-user-search>
                </el-form-item>
                <el-form-item label="联系人" prop="linkmanrpid">
                  <div class="select-content">
                    <e6-vr-select
                      v-model="baseInfoForm.linkmanrpid"
                      :data="contactList"
                      placeholder="联系人"
                      title=""
                      :props="{
                        id: 'contactId',
                        label: 'contactName'
                      }"
                      clearable
                      @change="handleContact"
                      @clear="handleClear"
                    ></e6-vr-select>
                    <el-button
                      type="text"
                      style="margin-left: 10px"
                      @click="addConcat"
                    >
                      添加联系人
                    </el-button>
                  </div>
                </el-form-item>
                <el-form-item label="联系电话" prop="contactPhone">
                  <el-input
                    placeholder="联系电话"
                    v-model="baseInfoForm.contactPhone"
                  ></el-input>
                </el-form-item>
                <el-form-item label="要求完成时间" prop="expecttime">
                  <el-date-picker
                    v-model="baseInfoForm.expecttime"
                    type="datetime"
                    placeholder="选择日期"
                    value-format="timestamp"
                    :picker-options="pickerOptions"
                    popper-class="special"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="联系地址" prop="defaultCity">
                  <e6-vr-select
                    style="width:300px"
                    v-model="baseInfoForm.defaultCity"
                    :data="addressList"
                    placeholder="联系地址"
                    title="联系地址"
                    virtual
                    clearable
                    :is-title="true"
                    remote
                    @filterChange="handleLoadAddressFilter"
                    @change="handleCitySelect"
                    :props="{
                      id: 'fullName',
                      label: 'fullName'
                    }"
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="详细地址" prop="insAddress">
                  <el-input
                    placeholder="详细地址"
                    v-model="baseInfoForm.insAddress"
                  ></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                  <el-input
                    v-model="baseInfoForm.remark"
                    placeholder="请输入备注"
                    type="textarea"
                    style="width: 500px"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </template>
          <!-- 基本信息 end -->
          <!-- 任务项设置   start -->
          <template>
            <div class="edit-title">任务项设置</div>
            <div class="edit-content" style="margin-bottom: 20px">
              <div class="associated-info">
                <div class="right-button">
                  <el-button type="primary" @click="addScene">
                    添加任务项
                  </el-button>
                  <el-button type="primary" @click="handleDelete">
                    删除任务项
                  </el-button>

                  <!-- <el-button type="primary">
                    导入任务项
                  </el-button> -->
                </div>
              </div>
              <el-table
                ref="multipleTable"
                :data="tableData"
                highlight-current-row
                @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection" width="50"></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="sceneName"
                  label="场景名称"
                  width="160"
                  align="center"
                  fixed
                  header-align="center"
                >
                  <template slot-scope="{ row }">
                    <span v-if="row.show">{{ row.sceneName }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="sceneTypeName"
                  label="场景类型"
                  width="160"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="{ row }">
                    <span v-if="row.show">{{ row.sceneTypeName }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="secondClassName"
                  label="安装组合"
                  width="160"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="{ row }">
                    <span v-if="row.show2">{{ row.secondClassName }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="thirdClassName"
                  label="报停商品"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="firstClassName"
                  label="商品分类"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="equipCode"
                  label="设备编号"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="realNo"
                  label="SIM卡号"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="needToRemove"
                  label="是否拆机"
                  width="200"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="scope">
                    <e6-vr-select
                      v-model="scope.row.needToRemove"
                      :data="
                        scope.$index > 0 ? needToRemoveList2 : needToRemoveList
                      "
                      placeholder="是否拆机"
                      title=""
                      clearable
                      @change="
                        () => {
                          handleNeedToRemove(scope.$index);
                        }
                      "
                    ></e6-vr-select>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="stopReason"
                  label="报停原因"
                  width="200"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      @click="slectReason(scope.row, scope.$index)"
                    >
                      {{
                        scope.row.stopReason
                          ? scope.row.stopReason
                          : "选择报停原因"
                      }}
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="requestDate"
                  label="要求报停时间"
                  width="300"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="scope">
                    <el-date-picker
                      v-model="scope.row.requestDate"
                      type="datetime"
                      placeholder="要求报停时间"
                      value-format="timestamp"
                      popper-class="special"
                      :picker-options="pickerOptions"
                      default-time="['00:00:00', '23:59:59']"
                      style="width:200px"
                    ></el-date-picker>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel" @click="handleGoBack">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </div>
      </section>
    </div>
    <!-- 选择场景 -->
    <select-scene
      :selectSceneDialog="selectSceneDialog"
      @handleClose="handleClose"
      @getData="getData"
      :terStatus="1"
      :corpId="baseInfoForm.corpId"
    ></select-scene>
    <!-- 查询开通的设备 -->
    <!-- 添加联系人弹框 -->
    <add-concat-dialog
      :addContactVisible="addContactVisible"
      @handleClose="handleClose"
      @updateList="updateList"
      :corpName="baseInfoForm.corpName"
      :isNeedReq="true"
      :corpId="baseInfoForm.corpId"
    ></add-concat-dialog>
    <!--报停原因弹框 -->
    <el-dialog
      v-dialogDrag
      title="报停原因"
      :visible.sync="reasonVisible"
      width="600px"
      :before-close="closeQuesDialog"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      custom-class="write-dialog"
    >
      <el-form
        ref="reasonForm"
        :model="reasonForm"
        :rules="reasonFormRules"
        :inline="true"
      >
        <el-form-item label="一级原因" prop="firstReason">
          <e6-vr-select
            :data="firstReasonList"
            v-model="reasonForm.firstReason"
            placeholder="一级原因"
            title="一级原因"
            clearable
          ></e6-vr-select>
        </el-form-item>
        <el-form-item label="二级原因" prop="secondReason">
          <e6-vr-select
            :data="secondReasonList"
            v-model="reasonForm.secondReason"
            placeholder="二级原因"
            title="二级原因"
            clearable
          ></e6-vr-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="closeQuesDialog">取消</el-button>
        <el-button type="primary" @click="confirmSelect">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import SelectScene from "@/components/workOrderManage/selectScene.vue";
import AddConcatDialog from "@/components/addConcatDialog.vue";
import addTask from "@/mixins/addTask";
import addService from "@/mixins/addService";
import { addStopService } from "@/api";
import { printError } from "@/utils/util";
import allUserSearch from "@/components/allUserSearch";
export default {
  name: "deviceStop",
  components: { SelectScene, AddConcatDialog, allUserSearch },
  data() {
    return {
      needToRemoveList: [
        { id: 1, label: "是" },
        {
          id: 0,
          label: "否"
        }
      ], //是否拆机下拉框
      needToRemoveList2: [
        { id: 2, label: "同上" },
        { id: 1, label: "是" },
        {
          id: 0,
          label: "否"
        }
      ],
      tableData: [],
      addContactVisible: false, //添加联系人弹框
      /******选择报停原因**********/
      reasonForm: {
        firstReason: "", //一级原因
        secondReason: "" //二级原因
      },
      currentIndex: "", //点击的行
      reasonFormRules: {
        firstReason: [
          {
            required: true,
            message: "请选择一级原因",
            trigger: ["blur", "change"]
          }
        ],
        secondReason: [
          {
            required: true,
            message: "请选择二级原因",
            trigger: ["blur", "change"]
          }
        ]
      },
      reasonVisible: false, //报停原因弹框是否显示
      firstReasonList: [
        { id: 1, label: "业务操作" },
        { id: 2, label: "客户经营变化" },
        { id: 3, label: "车辆原因" },
        { id: 4, label: "费用问题" },
        { id: 5, label: "服务问题" },
        { id: 6, label: "其他" },
        { id: 7, label: "地方政策" },
        { id: 8, label: "试用设备报停" },
        { id: 9, label: "被竞争对手挖走" },
        { id: 10, label: "产品问题" }
      ], //一级原因下拉框数据
      secondReasonList: [] //二级原因下拉框
    };
  },
  mixins: [base, addTask, addService],
  created() {},
  watch: {
    // 监听客户名称更改
    // "baseInfoForm.corpId": {
    //   immediate: true,
    //   handler(val) {
    //     if (val !== "") {
    //       console.log(val, "vl---");
    //       // this.getContactList(val);
    //     }
    //   }
    // },
    // 监听一级报停原因
    "reasonForm.firstReason": {
      immediate: true,
      handler(val) {
        if (val !== "") {
          let list = [
            {
              1: [
                { id: 1, label: "套餐变更" },
                { id: 2, label: "更换设备类型" },
                { id: 3, label: "拆移重装" },
                { id: 4, label: "车辆安装错误" }
              ]
            },
            {
              2: [
                { id: 5, label: "客户公司倒闭" },
                { id: 6, label: "车辆停运" },
                { id: 7, label: "客户项目撤销" },
                { id: 8, label: "解除承运商关系" },
                { id: 9, label: "车辆出售" },
                { id: 33, label: "客户公司转行" },
                { id: 34, label: "客户工商变更" },
                { id: 35, label: "业务线路调整" },
                { id: 36, label: "客户业务萎缩 " }
              ]
            },
            {
              3: [
                { id: 10, label: "事故车辆" },
                { id: 11, label: "车辆大修" },
                { id: 12, label: "车辆报废" },
                { id: 23, label: "外协车辆到期不合作" },
                { id: 24, label: "车辆过户" }
              ]
            },
            {
              4: [
                { id: 13, label: "到期不续费报停" },
                { id: 14, label: "客户欠费报停" },
                { id: 15, label: "费用价格高" }
              ]
            },
            {
              5: [
                { id: 17, label: "设备故障率高" },
                { id: 18, label: "服务时效慢" },
                { id: 31, label: "服务专业性差" }
              ]
            },
            {
              6: [
                { id: 19, label: "设备丢失或损坏" },
                { id: 20, label: "待机入网设备报停" },
                { id: 21, label: "联系不上客户报停" },
                { id: 32, label: "产品和客户需求不匹配" }
              ]
            },
            {
              7: [
                { id: 28, label: "当地无对接资质" },
                { id: 29, label: "当地政府指定供应商" }
              ]
            },
            {
              8: [
                { id: 25, label: "试用客户转销售" },
                { id: 26, label: "试用客户拆机停用" },
                { id: 27, label: "试用期内更换设备型" }
              ]
            },
            {
              9: [
                { id: 16, label: "购买别家设备" },
                { id: 22, label: "上游货主指定使用别家设备" }
              ]
            },
            {
              10: [{ id: 30, label: "使用效果未达客户期望" }]
            }
          ]; //二级原因下拉框数据
          let targetValue = [];
          for (var i = 0; i < list.length; i++) {
            for (let key in list[i]) {
              if (key == val) {
                targetValue = list[i][key];
              }
            }
          }
          this.secondReasonList = targetValue;
        } else {
          this.secondReasonList = [];
        }
      }
    }
  },
  computed: {},
  methods: {
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.baseInfoForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    //点击是否拆机
    handleNeedToRemove(row) {
      console.log(row, "row==");
    },
    //点击确定
    submit() {
      this.$refs.baseInfoForm.validate(valid => {
        if (valid) {
          this.baseInfoForm.contactName = this.filterSelect(
            this.contactList,
            "contactId",
            this.baseInfoForm.linkmanrpid
          ).contactName;
          this.baseInfoForm.corpName = this.filterSelect(
            this.corpList,
            "corpId",
            this.baseInfoForm.corpId
          ).corpName;
          if (!this.tableData.length) {
            this.$message.warning("请至少选择一个任务项");
            return;
          }
          if (
            this.tableData.some(item => {
              return !item.stopReason;
            })
          ) {
            this.$message.warning("请检查报停原因是否全部填写");
            return;
          }
          this.addStopServiceReq();
        }
      });
    },
    //创建报停单请求
    async addStopServiceReq() {
      try {
        for (let i = 0; i < this.tableData.length; i++) {
          if (i > 0) {
            if (this.tableData[i].needToRemove == 2) {
              this.tableData[i].needToRemove = this.tableData[
                i - 1
              ].needToRemove;
            }
            if (this.tableData[i].stopReason == "同上") {
              this.tableData[i].stopReason = this.tableData[i - 1].stopReason;
            }
          }
        }
        this.loading = true;
        let res = await addStopService({
          ...this.baseInfoForm,
          solveRpid:
            this.baseInfoForm.solueIsDept == 1
              ? this.baseInfoForm.departmentId.join("")
              : this.baseInfoForm.solveRpid,
          items: this.tableData
        });
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.closeTag(this.$route);
          this.routerPush({
            name: "workOrderManage/serviceListManage",
            params: {
              refresh: true
            }
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /********报停原因***********/
    slectReason(row, index) {
      this.reasonVisible = true;
      this.currentIndex = index;
    },
    //确定选中问题类型
    confirmSelect() {
      this.$refs.reasonForm.validate(valid => {
        if (valid) {
          //  给table赋值
          let firstReasonName = this.firstReasonList.filter(item => {
            return item.id == this.reasonForm.firstReason;
          });
          let secondReasonName = this.secondReasonList.filter(item => {
            return item.id == this.reasonForm.secondReason;
          });
          this.tableData[this.currentIndex].stopReason =
            firstReasonName[0].label + "/" + secondReasonName[0].label;
          this.closeQuesDialog();
        }
      });
    },
    //关闭报停原因选择框
    closeQuesDialog() {
      this.reasonForm = {
        firstReason: "",
        secondReason: ""
      };
      this.$refs.reasonForm.resetFields();
      this.reasonVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-content {
  padding: 20px 20px 2px;
  .e6-vr-select {
    width: 100%;
  }
}
.associated-info {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.table-input {
  width: 300px;
}
/deep/.write-dialog {
  .el-dialog__body {
    padding: 20px;
    .el-input {
      width: 220px;
    }
  }
}
</style>
